import React, { useEffect, useState } from "react";
import LoadingScreen from "../atoms/LoadingScreen";
import { getUserSummaryApi } from "../../api/getUserSummaryApi";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import CustomAppBar from "../organism/AppBar";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { useStyles } from "../../utils/Theme";
import { Trending } from "../atoms/Trending";
import { Navigate, Route, Routes } from "react-router-dom";
import { Profile } from "./Profile";
import { CreateLg } from "../organism/CreateLg";
// import InfiniteScroll from "react-infinite-scroll-component";
import { HomePage } from "../organism/HomePage";
import { useLazyGetHomeFeedQuery } from "../../redux/slice/homeFeed";
import { RelationRequest } from "./RelationRequest";
import { Seacrch } from "./Search";
import { Settings } from "./Settings";
import { AddEventFromHomePage } from "../molecules/AddEventsFromHomePage";
import { Explore } from "./explore";
import { NotificationPromt } from "../molecules/NotificationPromt";
import { PlanUpgrade } from "../molecules/PlanUpgrade";
import { Aniversary } from "../organism/Aniversary";
import { LeftNavigation } from "../molecules/LeftNavigation";
import { HomeFeedWelcomPromt } from "../atoms/HomeFeedWelcomPromt";
import { HomeFeedSkleton } from "../Skleton/HomeFeedSkleton";
import { Notification } from "./Notification";

const Home = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { isAppReady, userAgent } = useSelector((state) => state.config);
  const { mode } = useSelector((state) => state.createLg);
  const { allrelrev, relrevcode } = useSelector((state) => state.userSummary);
  const [getHomeFeed, { isLoading, data }] = useLazyGetHomeFeedQuery();

  useEffect(() => {
    getUserSummaryApi();
  }, []);
  useEffect(() => {
    if (allrelrev && relrevcode) fetchHomeFeed();
  }, [allrelrev, relrevcode]);
  //-----------------------Declare Functions Here-----------------------------//
  const fetchHomeFeed = (params = "") => {
    return getHomeFeed({
      params: params,
      allrelrev: allrelrev,
      relrevcode: relrevcode,
    });
  };
  if (isAppReady)
    setTimeout(() => {
      if (loading) setLoading(!loading);
    }, 1500);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <HomeFeedWelcomPromt />
          {userAgent !== "iOS" ? <NotificationPromt /> : null}
          <AddEventFromHomePage />
          {isAppReady === "self" && <CreateLg />}
          {isAppReady === true && (
            <CustomAppBar>
              {mode && <CreateLg />}
              <Grid
                container
                sx={{
                  // pt: 1,
                  pb: { xs: "57px", sm: 0, md: 0 },
                }}
                spacing={1}
              >
                <Grid
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "block",
                      lg: "block",
                    },
                  }}
                  item
                  xs={0}
                  sm={4}
                  md={4}
                  lg={3}
                >
                  <Stack
                    spacing={1}
                    sx={{ height: "90vh", position: "sticky", top: 73 }}
                  >
                    <Card
                      className={classes.root}
                      sx={{
                        height: "100%",
                        borderRadius: 0,
                        overflowY: "scroll",
                      }}
                    >
                      <LeftNavigation />
                    </Card>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  lg={6}
                  sx={{
                    px: { sm: 0, md: 0, lg: `32px !important` },
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: "background.main",
                      p: 0.7,
                    }}
                  >
                    {/* <InfiniteScroll
                      dataLength={0} //This is important field to render the next data
                      refreshFunction={() => window.location.reload()}
                      pullDownToRefresh
                      pullDownToRefreshThreshold={50}
                      pullDownToRefreshContent={<BusyPointer />}
                      releaseToRefreshContent={<BusyPointer />}
                    > */}
                    <Routes>
                      <Route path="*" element={<Navigate to="/" replace />} />
                      <Route
                        exact
                        path="/"
                        element={
                          <>
                            {isLoading ? (
                              <HomeFeedSkleton />
                            ) : (
                              <>
                                {data ? (
                                  <HomePage
                                    data={data}
                                    trigger={fetchHomeFeed}
                                    isLoading={isLoading}
                                  />
                                ) : (
                                  <HomeFeedSkleton />
                                )}
                              </>
                            )}
                          </>
                        }
                      />
                      <Route
                        exact
                        path="/profile/:id/*"
                        element={<Profile />}
                      />
                      <Route
                        exact
                        path="/relationrequest"
                        element={<RelationRequest />}
                      />
                      <Route exact path="/search" element={<Seacrch />} />
                      <Route exact path="/explorer" element={<Explore />} />
                      <Route exact path="/settings/*" element={<Settings />} />
                      <Route exact path="/upgrade" element={<PlanUpgrade />} />
                      <Route exact path="/alert" element={<Notification />} />
                    </Routes>
                    {/* </InfiniteScroll> */}
                  </Card>
                </Grid>
                <Grid
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block",
                    },
                    position: "sticky",
                    top: 75,
                  }}
                  item
                  xs={0}
                  sm={0}
                  md={0}
                  lg={3}
                >
                  <Stack
                    spacing={1}
                    sx={{ height: "90vh", position: "sticky", top: 75 }}
                  >
                    <Card
                      className={classes.root}
                      sx={{
                        height: "100%",
                        borderRadius: 0,
                        overflowY: "scroll",
                        backgroundColor: "background.main",
                      }}
                    >
                      <Trending />
                    </Card>
                  </Stack>
                </Grid>
              </Grid>
            </CustomAppBar>
          )}
        </>
      )}
    </>
  );
};
export default Home;
