import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WelcomePromt } from "../atoms/WelcomPromt";
import { ChooseRelationship } from "../molecules/ChooseRelationship";
import {
  resetCreateLg,
  updateCurrentPage,
  updateRelatedTo,
  updateRelationship,
} from "../../redux/slice/createLg";
import { LgAbout } from "../forms/LgAbout";
import { LgPersonal } from "../forms/LgPersonal";
import { useLazyGetLgAboutQuery } from "../../redux/slice/lgabout";
import { LgEducation } from "../forms/LgEducation";
import { LgOccupation } from "../forms/LgOccupation";
import { LgHealth } from "../forms/LgHealth";
import { LgPersonality } from "../forms/LgPersonality";
import { CreateLgMainPage } from "../atoms/CreateLgMainPage";
export const CreateLg = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { mode, currentPage, relationship, relatedTo } = useSelector(
    (state) => state.createLg
  );
  const [fetch, { data, isFetching, isLoading }] = useLazyGetLgAboutQuery();
  const [status, setStatus] = useState("relationshipPopupHeader");
  const dispatch = useDispatch();
  const handleClose = (value) => {
    if (value === "next") {
      dispatch(updateCurrentPage("about"));
    } else {
      dispatch(resetCreateLg());
      setStatus("relationshipPopupHeader");
    }
  };
  const handleNextOnLgAbout = (lgUrl, page) => {
    fetch(lgUrl, true);
    dispatch(updateCurrentPage(page));
  };
  return (
    <>
      {currentPage === "welcome" && <WelcomePromt />}
      {currentPage === "main" && (
        <CreateLgMainPage
          open={currentPage === "main" && mode === "new"}
          close={(e) => handleClose(e)}
        />
      )}
      {currentPage === "start" && (
        <ChooseRelationship
          open={currentPage === "start" && mode === "new"}
          setRelationship={(e) => dispatch(updateRelationship(e))}
          setRelatedTo={(e) => dispatch(updateRelatedTo(e))}
          relatedTo={relatedTo}
          relationship={relationship}
          close={(e) => handleClose(e)}
          setStatus={(status) => setStatus(status)}
        />
      )}
      {currentPage === "about" && (
        <>
          <LgAbout
            open={
              (mode === "self" || mode === "new") && currentPage === "about"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={(lg_id) => handleNextOnLgAbout(lg_id, "personal")}
          />
        </>
      )}
      {!isLoading && !isFetching && data && currentPage === "personal" && (
        <>
          <LgPersonal
            open={
              (mode === "self" || mode === "new") && currentPage === "personal"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={() => dispatch(updateCurrentPage("education"))}
            {...data.output}
          />
        </>
      )}
      {!isLoading && !isFetching && data && currentPage === "education" && (
        <>
          <LgEducation
            open={
              (mode === "self" || mode === "new") && currentPage === "education"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={() => dispatch(updateCurrentPage("occupation"))}
            {...data.output}
          />
        </>
      )}
      {!isLoading && !isFetching && data && currentPage === "occupation" && (
        <>
          <LgOccupation
            open={
              (mode === "self" || mode === "new") &&
              currentPage === "occupation"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={() => dispatch(updateCurrentPage("health"))}
            {...data.output}
          />
        </>
      )}
      {!isLoading && !isFetching && data && currentPage === "health" && (
        <>
          <LgHealth
            open={
              (mode === "self" || mode === "new") && currentPage === "health"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={() => dispatch(updateCurrentPage("personlity"))}
            {...data.output}
          />
        </>
      )}
      {!isLoading && !isFetching && data && currentPage === "personlity" && (
        <>
          <LgPersonality
            open={
              (mode === "self" || mode === "new") &&
              currentPage === "personlity"
            }
            mode={mode}
            close={() => dispatch(resetCreateLg())}
            onNext={() => dispatch(resetCreateLg())}
            {...data.output}
          />
        </>
      )}
    </>
  );
};
