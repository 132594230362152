import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import bgLogo from "../../assets/Img/logoBig.png";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage } from "../../redux/slice/createLg";

export const WelcomePromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { mode, currentPage } = useSelector((state) => state.createLg);
  const dispatch = useDispatch();
  return (
    <>
      <CustomDialog
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("display.text.text.welcome")}
          </Typography>
        }
        open={mode === "self" && currentPage === "welcome"}
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              onClick={() => dispatch(updateCurrentPage("about"))}
            >
              {t("display.text.button.createYourLg")}
            </Button>
          </Box>
        }
        iconPlacement="none"
      >
        <Box
          sx={{
            px: 2,
            pb: 2,
            background: `url(${bgLogo})`,
            backgroundSize: "400px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "#3d075f",
          }}
          className="derek"
        >
          {/* <Typography color="#a020f0" align="center" variant="h4">
            {t("display.text.text.introHeading")}
          </Typography> */}
          <Typography
            align="justify"
            sx={{ textIndent: "30px", mt: 2 }}
            paragraph
            variant="body2"
          >
            {t("display.text.text.introP1")}
          </Typography>
          <Typography align="justify" sx={{}} paragraph variant="body2">
            {t("display.text.text.introP2")}
          </Typography>
          <Typography align="justify" sx={{}} paragraph variant="body2">
            {/* <Box
              sx={{
                fontFamily: "Pacifico-Regular",
                color: "#a020f0",
                fontSize: "18px",
              }}
              component={"span"}
            >
              {t("display.text.text.introP3").split(" ")[0]}
            </Box> */}
            {t("display.text.text.introP3")}
          </Typography>
          <Typography align="justify" sx={{}} paragraph variant="body2">
            {t("display.text.text.introP4")}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
