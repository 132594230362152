import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomAvatar from "../../UI/CustomAvathar";
import {
  findDaysAndHours,
  findEventType,
  findWho,
  isSameDatePreviousYear,
} from "../../utils/functions";
import { t } from "i18next";
import { assetSource, emoji } from "../../utils/ListItems";
import { useNavigate } from "react-router-dom";
import { LifeEventContainer } from "../organism/LifeEventContainer";
import { MomentsContainer } from "../organism/MomentsContainer";
import { AchievementContainer } from "../organism/AchievementContainer";
import { AnecdotesContainer } from "../organism/AnecdotesContainer";
import { WisdomContainer } from "../organism/WisdomContainer";
import { AnnouncementContainer } from "../organism/AnnouncementContainer";
import { WishContainer } from "../organism/WishContainer";
import { RichTextViewer } from "../../UI/RichTextViewer";
import { HomeFeedCommentCard } from "../organism/HomeFeedCommentCard";
export const Feed = ({ data }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  let user = findWho(data?.lg_id);
  let eventType = findEventType(data?.catg);

  return (
    <>
      <Card
        sx={{
          borderTopLeftRadius: data?.comment_type ? "" : 0,
          borderTopRightRadius: data?.comment_type ? "" : 0,
          boxShadow:
            "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Stack spacing={1}>
          {data?.comment_type ? (
            data?.comment_type === "aniv" ? (
              <Stack
                spacing={1}
                direction={"row"}
                sx={{ display: "flex", p: 1, pb: 0, alignItems: "center" }}
              >
                <Box>
                  <img
                    src={`${assetSource}/logo_logoSmall`}
                    style={{ height: "50px", width: "50px" }}
                  />
                </Box>
                <Stack spacing={1}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: "18px", color: "#333333" }}
                  >
                    {isSameDatePreviousYear(data?.anniv_date)
                      ? t("display.text.text.aniversary", {
                          param1: data?.yrs_passed,
                        })
                      : t("display.text.text.exactAniversary", {
                          param1: data?.yrs_passed,
                        })}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack
                spacing={1}
                direction={"row"}
                sx={{ display: "flex", p: 1, pb: 0 }}
              >
                <Box
                  onClick={() => {
                    navigate(
                      `/profile/${data?.commenter_link.split("/")[4]}/about`
                    );
                  }}
                >
                  <CustomAvatar
                    src={data?.readURL2}
                    sx={{ height: "50px", width: "50px" }}
                  />
                </Box>
                <Stack spacing={1}>
                  <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
                    {`${data?.commenter_name} ${t(
                      data?.comment_type === "r"
                        ? "display.text.text.reacted"
                        : "display.text.text.commented"
                    )}`}
                  </Typography>
                  {data?.comment_type === "c" ? (
                    <Box
                      sx={{
                        display: "flex",
                        color: "#123984",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontStyle: "italic",
                      }}
                    >
                      {'" '}
                      <RichTextViewer
                        text={data?.comment_txt}
                        truncate={true}
                        truncateChar={150}
                      />
                      {'" '}
                    </Box>
                  ) : (
                    data?.comment_type === "r" && (
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={emoji[data?.comment_txt]}
                      />
                    )
                  )}
                </Stack>
              </Stack>
            )
          ) : null}
          <Box
            sx={{
              pt: data?.comment_type ? 1 : 0,
              px: {
                xs: data?.comment_type ? 2 : 0,
                lg: data?.comment_type ? 4 : 0,
              },
              pb: {
                xs: data?.comment_type ? 2 : 0,
                lg: data?.comment_type ? 3 : 0,
              },
            }}
          >
            <Card
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: "3px",
                borderTopStyle: "solid",
                borderTopColor: eventType?.color,
                boxShadow:
                  "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    startIcon={<CustomAvatar src={user?.readURL} />}
                    sx={{ color: "inherit" }}
                    onClick={() =>
                      navigate(
                        `/profile/${user?.link.split("/")[4]}/${eventType.url}`
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 400, fontSize: "16px" }}
                        component={"div"}
                      >
                        {user?.name}
                      </Typography>
                      <Stack direction={"row"} spacing={1}>
                        <Typography
                          sx={{
                            color: eventType?.color,
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          {t(`display.text.button.${eventType?.title}`)}
                        </Typography>
                      </Stack>
                    </Box>
                  </Button>
                  <Typography variant="caption" sx={{ p: 1 }}>
                    {findDaysAndHours(
                      data?.updated_date
                        ? data?.updated_date
                        : data?.created_date
                    )}
                  </Typography>
                </Stack>
              </Stack>
              {data?.comment_type === "c" ? (
                <HomeFeedCommentCard {...data} typ={data?.catg} />
              ) : (
                <Box>
                  {data?.catg === "le" && <LifeEventContainer {...data} />}
                  {data?.catg === "mem" && <MomentsContainer {...data} />}
                  {data?.catg === "ach" && <AchievementContainer {...data} />}
                  {data?.catg === "impress" && <AnecdotesContainer {...data} />}
                  {data?.catg === "wisdom" && <WisdomContainer {...data} />}
                  {data?.catg === "anc" && <AnnouncementContainer {...data} />}
                  {data?.catg === "wish" && (
                    <WishContainer
                      {...data}
                      style={data?.wish_style}
                      creator_name={data?.commenter_name}
                      homeFeed={true}
                    />
                  )}
                </Box>
              )}
            </Card>
          </Box>
        </Stack>
      </Card>
    </>
  );
};
