import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import { assetSource } from "../../utils/ListItems";
export const TipsPill = ({ src, message }) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          maxHeight: "80px",
          p: 0.7,
        }}
      >
        <Stack spacing={1} direction={"row"}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, color: "#000000" }}
          >
            {message}
          </Typography>
          <Box sx={{ height: "45px", width: "45px" }}>
            <img
              src={`${assetSource}/icon_tipIcon.svg`}
              style={{ height: "45px", width: "45px" }}
            />
          </Box>
        </Stack>
      </Card>
    </>
  );
};
