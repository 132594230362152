import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import Typography from "@mui/material/Typography";
import { findSelf } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/Bg/profileBadgeCardBG.svg";

export const LeftPaneProfileCard = () => {
  const self = findSelf();
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "primary.main",
          background: `url(${bg}), linear-gradient(93.6deg, #AB82C3 -4.57%, #8C679E 28.57%, #8C679E 77.11%, #AB82C3 107.01%)`,
          p: 1,
          "& :hover": { cursor: "pointer" },
        }}
        onClick={() => navigate(`/profile/${self?.lg_url}/about`)}
      >
        <Grid item xs={5}>
          <CustomAvatar
            sx={{ height: "100%", width: "100%" }}
            sx1={{ height: "inherit", width: "inherit" }}
            src={self?.readURL}
            customStyles={true}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            height: "auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            p: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
                alignItems: "flex-start",
                color: "#ffffff",
                fontSize: "22px",
                fontWeight: 500,
              }}
            >
              {`${self?.fname} ${self?.lname}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
