import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { findWho, formatNotificationParams } from "../../utils/functions";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
export const NotificationPill = ({ sx = {}, data }) => {
  const who = findWho(data?.target_lg_id);
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (data?.action_type === "redirect") {
      navigate(
        `/${data?.action_url.split(".")[2].split("/").slice(1).join("/")}`
      );
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          minHeight: "80px",
          backgroundColor: "rgba(1, 156, 222, 0.13)",
          p: 0.5,
          ...sx,
        }}
      >
        <Stack spacing={1} direction={"row"}>
          {
            <Box sx={{ height: "45px", width: "45px" }}>
              <CustomAvatar
                src={who?.readURL}
                sx={{ height: "45px", width: "45px" }}
              />
            </Box>
          }
          <Box>
            <Stack>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#333333" }}
              >
                {t(
                  `display.text.alert.title.${data?.notif_title}`,
                  formatNotificationParams(data?.notif_title_param)
                )}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400, color: "#666666" }}
              >
                {t(
                  `display.text.alert.message.${data?.notif_msg}`,
                  formatNotificationParams(data?.notif_msg_param)
                )}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {data?.action_btn_type && (
            <Button
              sx={{ p: 0, px: 0.5, fontSize: "12px" }}
              onClick={handleOnClick}
            >
              click here
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};
