import React, { useState } from "react";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { eventTypesIcons, homeFeedEventTypes } from "../../utils/ListItems";
import Button from "@mui/material/Button";
import { t } from "i18next";
import {
  findEventType,
  findOwner,
  findSelf,
  formatRelationship,
} from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { UserNameCard } from "../atoms/UserNameCard";
import { setAddEvent } from "../../redux/slice/addevent";
export const CreateNewEvent = ({ open, close = () => {} }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [config, setConfig] = useState({
    currentPage: "main",
    eventType: null,
    lg_id: "",
  });
  const [description, setDescription] = useState();
  const dispatch = useDispatch();
  const { ownedLg } = useSelector((state) => state.userSummary);

  //-----------------------Declare Functions Here-----------------------------//

  const handleClose = () => {
    close();
  };
  const formatOwnerAndColloborators = (type) => {
    switch (type) {
      case "wish": {
        return ownedLg.filter((item) => item.owner_type !== "Self");
      }
      case "impress": {
        return ownedLg.filter((item) => item.owner_type !== "Self");
      }
      default:
        return ownedLg;
    }
  };
  const handleSelection = (data) => {
    dispatch(
      setAddEvent({
        ...data,
      })
    );
    handleClose();
  };
  const handleSelectDescription = (data) => {
    setDescription(data);
  };
  const handleProceed = () => {
    if (description === "wisdom") {
      handleSelection({
        open: true,
        lg_id: findSelf()?.lg_id,
        type: description,
      });
    } else {
      setConfig({
        ...config,
        currentPage: "relation",
        eventType: description,
        lg_id: "",
      });
    }
  };

  return (
    <>
      <CustomDialogSwipeUp
        open={open}
        close={handleClose}
        title={
          config.currentPage === "main"
            ? t("display.text.text.selectOne")
            : config.currentPage === "relation" &&
              t(
                `display.text.button.${findEventType(config.eventType).title}`,
                {
                  count: 1,
                }
              )
        }
        fullWidth={true}
      >
        {config.currentPage === "main" && (
          <Stack spacing={2}>
            <Grid container>
              {homeFeedEventTypes.map((item, idx) => (
                <Grid
                  item
                  xs={3}
                  key={idx}
                  sx={{
                    border:
                      description === item ? "3px solid #999999" : "unset",
                    filter:
                      description === item
                        ? "drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.25))"
                        : "unset",
                    borderRadius: 1,
                    "&:hover": { backgroundColor: "rgba(140, 103, 158, 0.04)" },
                  }}
                >
                  <Button
                    startIcon={
                      <img
                        style={{ height: "50px", width: "50px" }}
                        src={eventTypesIcons[item]}
                      />
                    }
                    sx={{
                      flexDirection: "column",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => handleSelectDescription(item)}
                    disableRipple={true}
                  >
                    <Typography
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: { xs: "64px", md: "128px" },
                        whiteSpace: "wrap",
                        color: "#333333",
                      }}
                    >
                      {t(`display.text.button.${findEventType(item).title}`, {
                        count: 1,
                      })}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
            {description && (
              <Card
                sx={{
                  width: "100%",
                  p: 1,
                  backgroundColor: "background.main",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                  }}
                  // paragraph
                >
                  <span
                    style={{
                      fontWeight: 600,
                      color: findEventType(description).color,
                    }}
                  >{`${t(
                    `display.text.button.${findEventType(description).title}`
                  )} : `}</span>
                  {t(`display.text.text.description${description}`)}
                </Typography>
              </Card>
            )}
            <Card
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                disabled={!description}
                variant="contained"
                onClick={handleProceed}
              >
                {t("display.text.button.proceed")}
              </Button>
            </Card>
          </Stack>
        )}
        {config.currentPage === "relation" && (
          <Card>
            {formatOwnerAndColloborators(config.eventType).map((item, idx) => (
              <div key={idx}>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => {
                    handleSelection({
                      open: true,
                      type: config.eventType,
                      lg_id: item.lg_id,
                    });
                  }}
                >
                  <UserNameCard
                    src={item.readURL}
                    name={`${item.fname} ${item.lname}`}
                    relation={formatRelationship(item.lg_id)}
                    ownerType={findOwner(item.lg_id)?.owner_type}
                  />
                </Button>
              </div>
            ))}
          </Card>
        )}
      </CustomDialogSwipeUp>
    </>
  );
};
