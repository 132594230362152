import { green, red } from "@mui/material/colors";
import { resetAuthentication } from "../redux/slice/authentication";
import { store } from "../redux/store";
import {
  OS,
  achievementReactions,
  anecdotesReaction,
  announcementReactions,
  baseURL,
  lifeEventReactions,
  lifeEventSticker,
  momentsReactions,
  regularExpressions,
  somethingWentWrong,
  wisdomReactions,
  wishReactions,
} from "./constants";
import { setAlert } from "../redux/slice/alert";
import { fileTypes, relationType } from "./ListItems";
import { t } from "i18next";
import { updateOwnedLg, updateUserPlan } from "../redux/slice/userSummary";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { updateNudgePost } from "../redux/slice/notification";

export const mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) || // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const chooseDevice = (value) => {
  if (value < 600) return "sm";
  else if (value < 1200) return "md";
  else return "lg";
};

export const detectOS = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.mac;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.ios;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.win;
  } else if (/Android/.test(userAgent)) {
    os = OS.android;
  } else if (!os && /Linux/.test(platform)) {
    os = OS.lin;
  }

  return os;
};

export const getApi = (url, params = "") => {
  const { authentication } = store.getState();
  const requestOptions = {
    method: "GET",
    headers: {
      UserId: authentication.usrId,
      token: authentication.usrToken,
    },
  };
  try {
    let response = fetch(`${baseURL}/${url}?${params}`, requestOptions)
      .then((response) => {
        if (response.status === 200) return response.json();
        else if (response.status === 401) {
          store.dispatch(resetAuthentication());
          return new Promise((resolve, reject) => reject);
        } else if (response.status === 422) {
          return response.json();
        }
        return response.json();
      })
      .then((responseJSON) => responseJSON);
    return response;
  } catch (e) {
    store.dispatch(setAlert(somethingWentWrong));
    console.log(e);
  }
};
export const postApi = (url, formData = {}) => {
  const { authentication } = store.getState();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserId: authentication.usrId,
      token: authentication.usrToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    let response = fetch(`${baseURL}/${url}`, requestOptions)
      .then((response) => {
        if (response.status === 200) return response.json();
        else if (response.status === 401) {
          store.dispatch(resetAuthentication());
          return new Promise((_, reject) => reject);
        } else if (response.status === 422) {
          return response.json();
        }
        return response.json();
      })
      .then((responseJSON) => responseJSON);
    return response;
  } catch (e) {
    store.dispatch(setAlert(somethingWentWrong));
    console.log(e);
  }
};

export const findSelf = () => {
  const { userSummary } = store.getState();
  if (userSummary.ownedLg.length > 0) {
    return userSummary.ownedLg.filter((item) => item.owner_type === "Self")[0];
  }
  return undefined;
};

export const capitalizeFirstLetter = (string) => {
  const output = string.charAt(0).toUpperCase() + string.slice(1);
  return output;
};

export const formatRelationship = (lgId) => {
  const { userSummary } = store.getState();
  let relationshipList = userSummary.relations;
  if (relationshipList.length > 0) {
    let selectedPerson = relationshipList.filter(
      (item) => item.userId === lgId
    );
    if (selectedPerson.length > 0) {
      if (
        selectedPerson[0].relation !== "Wife" &&
        selectedPerson[0].relation !== "Husband" &&
        (selectedPerson[0].relation_source === "marriage" ||
          selectedPerson[0].relation_thru !== "self")
      )
        return `${selectedPerson[0].relation} In Law`;
      else if (selectedPerson[0].relation === "Self") return "You";
      else if (
        ((selectedPerson[0].relation.toLowerCase().endsWith("son") ||
          selectedPerson[0].relation.toLowerCase().endsWith("daughter") ||
          selectedPerson[0].relation.toLowerCase().endsWith("grandchild")) &&
          selectedPerson[0].relation_thru === "spouse") ||
        ((selectedPerson[0].relation.toLowerCase().endsWith("father") ||
          selectedPerson[0].relation.toLowerCase().endsWith("mother") ||
          selectedPerson[0].relation.toLowerCase().endsWith("grandparent")) &&
          selectedPerson[0].relation_source.toLowerCase().startsWith("step"))
      ) {
        return `${selectedPerson[0].relation} (step)`;
      } else return `${selectedPerson[0].relation}`;
    } else return null;
  }
};

export const chooseOwnerTypeColor = (ownerType) => {
  switch (ownerType) {
    case "Self":
      return green[500];
    case "Owner":
      return "#F42970";
    case "Collab":
      return "#76E14D";
    default:
      return "transparent";
  }
};

export const findOwner = (lgId) => {
  const { userSummary } = store.getState();
  if (userSummary.ownedLg.length > 0) {
    return userSummary.ownedLg.find((item) => item.lg_id === lgId);
  }
  return undefined;
};
export const findExactOwner = (lgId) => {
  const { userSummary } = store.getState();
  if (userSummary.ownedLg.length > 0) {
    let usr = userSummary.ownedLg.find((item) => item.lg_id === lgId);
    if (usr) return usr.owner_type === "Owner" || usr.owner_type === "Self";
    return undefined;
  }
  return undefined;
};

export const chooseStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "active" || "Y":
      return green[500];
    default:
      return red[500];
  }
};

export const sortRelation = (a, b) => {
  // const { userSummary } = store.getState();
  // let x = userSummary.ownedLg.find((user) => user.lg_id === a.userId);
  // let y = userSummary.ownedLg.find((user) => user.lg_id === b.userId);
  // let orderPriority = { Self: 1, Owner: 2, Collab: 3 };
  // let res = 0;
  // if (x && y) {
  //   //sort accoring to owner_type. Order Priority is Self,Owner,Collab.
  //   res = orderPriority[x.owner_type] - orderPriority[y.owner_type];
  //   if (res !== 0) {
  //     return res;
  //   }
  //   //sort according to name in alphabetical order if owner_type of both is Owner or Collab.
  //   if (
  //     x.owner_type === y.owner_type &&
  //     (x.owner_type === "Owner" || y.owner_type === "Collab")
  //   ) {
  //     res = a.name.localeCompare(b.name);
  //     if (res !== 0) {
  //       return res;
  //     }
  //   }
  // } else if (!x && !y) {
  //   //if not in userSummary
  //   //sort according to rel_chain length in ascending order
  //   let l1 = a.rel_chain ? a.rel_chain.length : 0;
  //   let l2 = b.rel_chain ? b.rel_chain.length : 0;
  //   res = l1 - l2;
  // } else {
  //   //if one of them is in userSummary
  //   //sort according to owner_type. Order Priority is Self,Owner,Collab.
  //   res = x ? -1 : 1;
  // }
  // return res;
  return a.rel_degree - b.rel_degree;
};

export const getTabIndexfromTab = (tab) => {
  switch (tab) {
    case "about":
      return 0;
    case "lifeEvents":
      return 1;
    case "frozenMoments":
      return 2;
    case "achievements":
      return 3;
    case "loveAndWishes":
      return 4;
    case "passDowns":
      return 5;
    case "anecdotes":
      return 6;
    case "announcements":
      return 7;
    default:
      return 0;
  }
};

export const getTabFromTabIndex = (index) => {
  switch (index) {
    case 0:
      return "about";
    case 1:
      return "lifeEvents";
    case 2:
      return "frozenMoments";
    case 3:
      return "achievements";
    case 4:
      return "loveAndWishes";
    case 5:
      return "passDowns";
    case 6:
      return "anecdotes";
    case 7:
      return "announcements";
    default:
      return "about";
  }
};

export const getRelRevAndLgCode = (lgId) => {
  const { relations } = store.getState().userSummary;
  if (relations.length > 0) {
    let rel = relations.find((item) => item.userId === lgId);
    if (rel !== undefined) {
      return { relrev_bin: rel.relrev_bin, lgcode_rev: rel.lgcode_rev };
    }
    return { relrev_bin: "public", lgcode_rev: "" };
  }
  return { relrev_bin: "public", lgcode_rev: "" };
};

export const prepareHeaders = (headers, { getState }) => {
  const { usrToken, usrId } = getState().authentication;
  headers.set("UserId", usrId);
  headers.set("token", usrToken);
  return headers;
};

export const transformErrorResponse = (response, meta, arg) => {
  if (meta.response.status === 401) {
    store.dispatch(resetAuthentication());
  } else if (meta.response.status === 422) {
    store.dispatch(setAlert(response.data));
  } else if (meta.response.status === 200) {
    updateMetaData(response);
    store.dispatch(setAlert({ ...response, error: true }));
  } else {
    store.dispatch(setAlert(somethingWentWrong));
  }
};

export const formatDescription = (des, mentionsArr) => {
  try {
    let desc = JSON.parse(des);
    mentionsArr.forEach((element) => {
      for (let i = 0; i < Object.keys(desc.entityMap).length; i++) {
        if (element.userId === desc.entityMap[i].userId) {
          desc.entityMap[i] = {
            data: { mention: element },
            mutability: "SEGMENTED",
            type: "mention",
          };
        }
      }
    });
    return desc;
  } catch {
    return des;
  }
};

export const getRelRevAndLgCodeFromUrl = (url) => {
  const { relations } = store.getState().userSummary;
  if (relations.length > 0) {
    let rel = relations.find((item) => item.link.split("/")[4] === url);
    if (rel !== undefined) {
      return { relrev_bin: rel.relrev_bin, lgcode_rev: rel.lgcode_rev };
    }
    return { relrev_bin: "public", lgcode_rev: "" };
  }
  return { relrev_bin: "public", lgcode_rev: "" };
};

export const formatGetApiResponse = (obj, type) => {
  let arr = Object.keys(obj);
  let objs = {};
  arr.forEach((item) => {
    let temp = item.split("_");
    objs = Object.assign(objs, {
      [temp[0] === type ? temp[1] : item]: obj[item],
    });
  });
  return objs;
};

export const formatResponseOutput = (output, response, type) => {
  let formatedArr = output
    .map((item) => formatGetApiResponse(item, type))
    .map((item) => ({
      ...item,
      desc: formatDescription(item.desc, response.mentions_lg_arr),
      comment_txt:
        item?.comment_type === "c"
          ? formatDescription(item.comment_txt, response.mentions_lg_arr)
          : item?.comment_txt,
    }));
  return formatedArr;
};

export const updateReactionOnMutation = (item, formData) => {
  if (item.id === formData.id)
    return {
      ...item,
      my_reaction: formData.reaction,
      reaction_cnt: calculateReactionCount(formData),
      uniq_reaction_list: calculateUniqueReaction(formData),
    };
  return item;
};

export const updateCommentCountOnMutation = (item, formData, typ = "inc") => {
  if (item.id === formData.entry_id)
    return {
      ...item,
      comment_cnt:
        typ === "inc"
          ? (Number(item.comment_cnt) + 1).toString()
          : (Number(item.comment_cnt) - 1).toString(),
    };
  return item;
};

export const calculateReactionCount = (formData) => {
  let count = Number(formData.reaction_cnt);
  if (formData.my_reaction === "" && formData.reaction !== "") {
    return (count + 1).toString();
  } else if (formData.my_reaction !== "" && formData.reaction === "")
    return count > 0 ? (count - 1).toString() : count.toString();
  else if (formData.my_reaction === "" && formData.reaction === "")
    return count.toString();
  return count.toString();
};

export const calculateReaction = (reaction, my_reaction) => {
  if (reaction === my_reaction) return "";
  else if (reaction !== my_reaction) return reaction;
};

export const calculateUniqueReaction = (formData) => {
  let count = Number(formData.reaction_cnt);
  let reaction = formData.uniq_reaction_list
    ? formData.uniq_reaction_list.split(",")
    : [];
  if (reaction.length === 3) {
    if (count === 3) {
      if (formData.my_reaction !== "" && formData.reaction === "")
        return reaction.filter((item) => item !== formData.my_reaction);
      else if (formData.my_reaction === "" && formData.reaction !== "") {
        return reaction.join(",");
      } else if (formData.my_reaction !== "" && formData.reaction !== "") {
        reaction[count - 1] = formData.reaction;
        return reaction.join(",");
      }
    } else if (count > 3) return reaction.join(",");
  } else if (reaction.length === 2) {
    if (count === 2) {
      if (formData.my_reaction !== "" && formData.reaction === "")
        return reaction
          .filter((item) => item !== formData.my_reaction)
          .join(",");
      else if (formData.my_reaction === "" && formData.reaction !== "") {
        return reaction.concat(formData.reaction).join(",");
      } else if (formData.my_reaction !== "" && formData.reaction !== "") {
        if (formData.reaction === reaction[count - 2]) {
          return [reaction[count - 2]].join(",");
        } else {
          reaction[count - 1] = formData.reaction;
          return reaction.join(",");
        }
      }
    } else if (count > 2) {
      if (formData.my_reaction === "" && formData.reaction !== "") {
        return reaction.concat(formData.reaction).join(",");
      } else return reaction.join(",");
    }
  } else if (reaction.length === 1) {
    if (count === 1) {
      if (formData.my_reaction !== "" && formData.reaction === "") return null;
      else if (formData.my_reaction === "" && formData.reaction !== "") {
        if (formData.reaction === reaction[count - 1]) {
          return reaction.join(",");
        } else return reaction.concat(formData.reaction).join(",");
      } else if (formData.my_reaction !== "" && formData.reaction !== "") {
        reaction[count - 1] = formData.reaction;
        return reaction.join(",");
      }
    } else if (count > 1) {
      if (formData.my_reaction === "" && formData.reaction !== "") {
        return reaction.concat(formData.reaction).join(",");
      } else if (formData.my_reaction !== "" && formData.reaction !== "") {
        if (formData.reaction === reaction[count - 1]) {
          return reaction.join(",");
        } else return reaction.concat(formData.reaction).join(",");
      } else return reaction.join(",");
    }
  } else if (reaction.length === 0) {
    if (count === 0) {
      if (formData.my_reaction !== "" && formData.reaction === "") return null;
      else if (formData.my_reaction === "" && formData.reaction !== "")
        return reaction.concat(formData.reaction).join(",");
    } else if (count > 1) {
      if (formData.my_reaction === "" && formData.reaction !== "") {
        return reaction.concat(formData.reaction).join(",");
      } else return reaction.join(",");
    }
  }
};

export const findDaysAndHours = (date) => {
  let diff = (new Date().getTime() - new Date(date).getTime()) / 1000;
  let min = Math.abs(Math.round(diff / 60));
  let hours = Math.abs(Math.round(diff / (60 * 60)));
  let days = Math.abs(Math.round(diff / (60 * 60 * 24)));
  let sec = Math.abs(Math.round(diff));
  if (days >= 1) return `${days} days ago`;
  else if (hours >= 1) return `${hours} hours ago`;
  else if (min >= 1) return `${min}min ago`;
  else return `${sec} sec ago`;
};

export const optimisticUpdateReaction = (arr, formData) => {
  let self = findSelf();
  let selfLgId = self?.lg_id;
  let commenterExist = arr.find((item) => item.commenter_lg_id === selfLgId);
  if (commenterExist) {
    if (formData.reaction === "") {
      return arr.filter((item) => item.commenter_lg_id !== selfLgId);
    }
    let newArr = arr.map((item) => {
      if (item.commenter_lg_id === selfLgId) {
        return {
          ...item,
          comment: formData.reaction,
          comment_date: new Date().toISOString(),
        };
      }
      return item;
    });
    return newArr;
  } else {
    return [
      {
        comment: formData.reaction,
        comment_date: new Date().toISOString(),
        commenter_lg_id: selfLgId,
        commenter_link: `////${self?.lg_url}`,
        commenter_name: `${self?.fname} ${self?.lname}`,
        readURL: self?.readURL,
      },
      ...arr,
    ];
  }
};

export const findEventType = (type) => {
  switch (type) {
    case "le":
      return {
        title: "lifeEvents",
        delete: "Event",
        reaction: lifeEventReactions,
        fileTypes: "event",
        color: "#F42970",
        url: "lifeEvents",
      };

    case "mem":
      return {
        title: "frozenMoments",
        delete: "Memory",
        reaction: momentsReactions,
        fileTypes: "memory",
        color: "#B34DC2",
        url: "frozenMoments",
      };

    case "ach":
      return {
        title: "achievements",
        delete: "Achievement",
        reaction: achievementReactions,
        fileTypes: "achmnt",
        color: "#76E14D",
        url: "achievements",
      };

    case "anc":
      return {
        title: "announcements",
        delete: "Announcement",
        reaction: announcementReactions,
        fileTypes: "anc",
        color: "#C28800",
        url: "announcements",
      };

    case "wish":
      return {
        title: "loveAndWishes",
        delete: "Wish",
        reaction: wishReactions,
        fileTypes: "wish",
        color: "#FE9E27",
        url: "loveAndWishes",
      };

    case "impress":
      return {
        title: "anecdotes",
        delete: "Impress",
        reaction: anecdotesReaction,
        color: "#11BCFF",
        url: "anecdotes",
      };

    case "wisdom":
      return {
        title: "passDowns",
        delete: "Wisdom",
        reaction: wisdomReactions,
        color: "#8433EC",
        url: "passDowns",
      };
    case "profile":
      return { title: "profileReaction" };
    case "random":
      return { title: "randomThoughts", color: "#11BCFF" };
    case "info":
      return {
        color: "#009FE3",
        fontColor: "#FFFFFF",
        icon: <InfoOutlinedIcon />,
      };
    case "error":
      return {
        color: "#FF3440",
        fontColor: "#FFFFFF",
        icon: <DoNotDisturbIcon />,
      };
    case "success":
      return {
        color: "#00B56A",
        fontColor: "#FFFFFF",
        icon: <CheckCircleOutlineIcon />,
      };

    default:
      return;
  }
};

export const generateRandomLifeEventSticker = (eventType) => {
  let sticker = lifeEventSticker.filter(
    (item) => item.split("_")[0] === eventType.toLowerCase()
  );
  return {
    sticker: sticker,
    count: Math.floor(Math.random() * sticker.length),
  };
};

export const formatDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var d = Date.parse(date);
  // date = date.toDateString();
  var today = new Date(d);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + months[Number(mm)] + "-" + yyyy;
  return today;
};

export const isBlocked = (lg_id) => {
  const { blockedLg } = store.getState().userSummary;
  return Boolean(blockedLg.find((item) => item.blocking_lg === lg_id));
};

export const formatRichTextEditorData = (value) => {
  let description = JSON.parse(JSON.stringify(value));
  let arr = [];
  for (let i = 0; i < Object.keys(description.entityMap).length; i++) {
    if (description.entityMap[i].type === "mention") {
      description.entityMap[i] = {
        userId: description.entityMap[i].data.mention.userId,
      };
      arr.push(description.entityMap[i].userId);
    }
  }
  return { arr: arr, desc: description };
};

export const findWho = (lg_id) => {
  const { relations } = store.getState().userSummary;
  return relations.find((item) => item.userId === lg_id);
};

export const isYoutubeLink = (url) => {
  let p = regularExpressions.isYoutubeLink;
  if (url.match(p)) {
    return true;
  } else {
    return false;
  }
};

export const validateRichText = (value, isDirty) => {
  if (isDirty) {
    if (value === "") return false;
    else if (value !== "") return true;
  } else return true;
};

export const findAllowedFileType = (
  lg_id,
  typ,
  selectedType,
  label,
  expectedType = "image"
) => {
  const { userPlan } = store.getState().userSummary;
  const usr = userPlan;
  if (usr) {
    let events = usr[`${findEventType(typ).fileTypes}_file_types`];
    let allowedMimeTypes = events.file_mime_types
      .filter((item) => item.label.toLowerCase() === label.toLowerCase())
      .map((item) => item.ftype)
      .filter((item) => item.split("/")[0] === selectedType.split("/")[0]);
    let isValidMimeType = events.file_mime_types
      .map((item) => item.ftype)
      .filter((item) => item.split("/")[0] === expectedType)
      .find((item) => item === selectedType);
    let maxSize = events.file_mime_types
      .filter((item) => item.label.toLowerCase() === label.toLowerCase())
      .find((item) => item.ftype === selectedType);
    return {
      allowedMimeTypes: allowedMimeTypes.toString(),
      isValidMimeType: Boolean(isValidMimeType),
      maxSize: maxSize?.max_size,
    };
  }
  return {};
};

export const getFileType = (value, type = "value") => {
  if (type === "value") return fileTypes.find((item) => item.value === value);
  else if (type === "label")
    return fileTypes.find((item) => item.label === value);
};

export const generateFileType = (lg_id, typ) => {
  const { userPlan } = store.getState().userSummary;
  const usr = userPlan;
  let arr = [];
  if (usr) {
    let events = usr[`${findEventType(typ).fileTypes}_file_types`];
    events.types_allowed.forEach((item) => {
      fileTypes.forEach((element) => {
        if (element.label === item) {
          arr.push(element);
        }
      });
    });
  }
  return arr;
};

export const getLabelAndValue = (value, arr = [], type = "value") => {
  if (value) {
    if (type === "value") return arr.find((item) => item.value === value);
    else if (type === "label") return arr.find((item) => item.label === value);
  }
  return null;
};

export const getChipValue = (value, types) => {
  let temp = value.split("|");
  let arr = [];
  temp.forEach((item) => {
    types.forEach((element) => {
      if (element.value === item) arr.push(element);
    });
  });
  return arr;
};

export const validatePlan = (typ = "", file_code = "") => {
  const { userPlan } = store.getState().userSummary;
  if (typ === "" && file_code === "" && userPlan)
    return Number(userPlan.used_flinks) < Number(userPlan.total_flinks)
      ? "active"
      : "upgrade";
  const code = userPlan.flinks_map.find(
    (item) => item.rec_type === typ && item.file_code === file_code
  );
  if (userPlan && code) {
    return Number(userPlan.used_flinks) + Number(code.flinks) <=
      Number(userPlan.total_flinks)
      ? "active"
      : "upgrade";
  }
  return "active";
};

export const chooseGenderFromValue = (value) => {
  if (value) {
    value = value.toLowerCase();
    return value === "father" ||
      value === "son" ||
      value === "brother" ||
      value === "husband"
      ? "male"
      : "female";
  }
};

export const autoCompleteDropdownValidation = (value, validateArray) => {
  value = value?.value || value;
  if (value !== null && value !== "" && value) {
    return Boolean(
      validateArray.find((item) => item.value === value || item.label === value)
    );
  } else if (value === null || value === "" || !value) {
    return true;
  }
};

export const getLabel = (list) => {
  return list.map((item) => item.label);
};

export const formatFreeSoloChipInput = (input) => {
  return input.map((item) => (item?.value ? item.value : item));
};

export const updateProfileReactions = (reactionArr, formData) => {
  if (reactionArr.length === 0)
    return [
      { reaction: formData.reaction, reaction_cnt: "1", my_reaction: "yes" },
    ];
  let reaction = reactionArr.find(
    (item) => item.reaction === formData.reaction
  );
  if (reaction) {
    return reactionArr
      .map((item) => {
        if (item.my_reaction === "") {
          return {
            ...item,
            reaction_cnt: (Number(item.reaction_cnt) + 1).toString(),
            my_reaction: "yes",
          };
        } else if (item.my_reaction === "yes") {
          if (Number(item.reaction_cnt) === 1) {
            if (item.reaction === formData.reaction) return null;
            else return item;
          } else
            return {
              ...item,
              reaction_cnt: (Number(item.reaction_cnt) - 1).toString(),
              my_reaction: "",
            };
        }
      })
      .filter((item) => item !== null);
  } else
    return [
      ...reactionArr,
      { reaction: formData.reaction, reaction_cnt: "1", my_reaction: "yes" },
    ];
};

export const formatPossibleRelationArr = (possibleParentArr) => {
  if (possibleParentArr) {
    const ids = possibleParentArr.map(({ relation_of_lg }) => relation_of_lg);
    const filteredArr = possibleParentArr.filter(
      ({ userId }, index) => !ids.includes(userId, index + 1)
    );
    return filteredArr;
  }
  return [];
};

export const getCoverPicReadURL = (lg_id) =>
  `https://stor1.famscape.com/lgstore/lg-${lg_id}/reg/gen/bannerpic`;

export const getAllRelRev = () => {
  const { userSummary } = store.getState();
  if (userSummary.allrelrev && userSummary.relrevcode)
    return {
      relrevcode: userSummary.relrevcode,
      allrelrev: userSummary.allrelrev,
    };
  return { relrevcode: "", allrelrev: "" };
};
export const getActiveUsers = () => {
  const { relations } = store.getState().userSummary;
  if (relations) {
    return relations.filter(
      (item) => item.self_usr === "Y" && item.relation !== "Self"
    );
  }
  return [];
};

export const isAlive = (lg_id) => {
  const { relations } = store.getState().userSummary;
  if (relations) {
    let relative = relations.find((item) => item.userId === lg_id);
    if (relative.deceased === "No") return true;
    return false;
  }
};

export const isSelf = (lg_id) => {
  const { userSummary } = store.getState();
  if (userSummary.ownedLg.length > 0) {
    let user = userSummary.ownedLg.find((item) => item.lg_id === lg_id);
    if (user) return user.owner_type === "Self";
  }
  return false;
};

const getAncestors = (currentAnchor, default_path) => {
  return function filterFn(relObj) {
    return relObj.rel_chain.startsWith(currentAnchor);
  };
};

const getMaxAncestors = (max_ancestor_dist) => {
  return function filterFn(relObj) {
    return relObj.rel_chain.length === max_ancestor_dist;
  };
};

const createPersonObject = (personObj, famTreeList) => {
  let personObject = Object.assign({}, personObj);
  let spouse_arr = famTreeList.filter(function (item) {
    return item.spouse_of_lg_id === personObj.lg_id;
  });
  let allAncestorArr = famTreeList.filter(function (item) {
    return item.rel_chain.startsWith("I");
  });
  let finalSpouseArr = [];
  spouse_arr.forEach((item) => {
    let tempArr = allAncestorArr.filter((item1) => item1.lg_id === item.lg_id);
    if (tempArr.length > 0) {
      finalSpouseArr.push(tempArr[0]);
    } else {
      finalSpouseArr.push(item);
    }
  });
  personObject.spouses = finalSpouseArr;
  let childrenArr = famTreeList.filter(function (item) {
    return (
      item.father_lg_id === personObj.lg_id ||
      item.mother_lg_id === personObj.lg_id
    );
  });
  let siblingArr = famTreeList.filter(function (item) {
    return (
      (item.father_lg_id === personObj.father_lg_id &&
        personObj.father_lg_id !== null) ||
      (item.mother_lg_id === personObj.mother_lg_id &&
        personObj.mother_lg_id !== null)
    );
  });
  let siblingArrSelfIndex = siblingArr.findIndex((item) => {
    return item.lg_id === personObject.lg_id;
  });
  let siblingArrCnt = siblingArr.length;
  personObject.children = [];
  if (childrenArr.length > 0) {
    let finalTempArr = [];
    let tempArr = [
      ...childrenArr.map((item) => createPersonObject(item, famTreeList)),
    ];
    tempArr.forEach((innerArray) => {
      innerArray.forEach((lowestObj) => {
        finalTempArr.push(lowestObj);
      });
    });
    personObject.children = [...finalTempArr];
  }
  let dummyChildObj = {
    name: "dummyChildForSpouseGap",
    spouses: [],
    children: [],
    gender: "",
  };
  let final_obj_arr = [personObject];
  if (siblingArrCnt > 1 && siblingArrSelfIndex + 1 < siblingArrCnt) {
    for (let i = 0; i < personObject.spouses.length; i++) {
      final_obj_arr = [...final_obj_arr, dummyChildObj];
    }
  }
  return final_obj_arr;
};

export const createFamTreeJson = (famTreeList, currentAnchor) => {
  if (famTreeList) {
    const default_path = "F";
    var ancestor_arr = famTreeList.filter(
      getAncestors(currentAnchor, default_path)
    );
    var max_ancestor_dist = Math.max(
      ...ancestor_arr.map((o) => o.rel_chain.length)
    );
    var root_ancestor = ancestor_arr.filter(
      getMaxAncestors(max_ancestor_dist)
    )[0];
    var jsonObject = createPersonObject(root_ancestor, famTreeList);
    return { tree: jsonObject, root_ancestor: root_ancestor };
  }
  return { tree: [], root_ancestor: {} };
};

export const getDynamicPathClass = ({ target }) => {
  if (target.data.name === "dummyChildForSpouseGap") return "rootNodeAlways";
  return "";
};
export const findDateDifference = (date) => {
  let diff = (new Date().getTime() - new Date(date).getTime()) / 1000;
  // let min = Math.abs(Math.round(diff / 60));
  // let hours = Math.abs(Math.round(diff / (60 * 60)));
  let days = Math.abs(Math.round(diff / (60 * 60 * 24)));
  // let sec = Math.abs(Math.round(diff));
  return {
    // min: min,
    //  hours: hours,
    days: days,
    //  sec: sec
  };
};

export const formatProfileReactions = (value) => {
  if (value) {
    let arr = [];
    value.forEach((item) => {
      if (item.my_reaction === "yes") {
        arr.push(item.reaction);
      }
    });
    return arr;
  }
  return [];
};

export const formatPipe = (input) => {
  let temp = input.split("|");
  let output = temp
    .join(", ")
    .replace(/,(?=[^,]*$)/, t("display.text.lgAbout.and"));
  return output;
};

export const findChildrenLength = (data) => {
  let maxChildrenLength = 0;

  function traverse(children) {
    for (const child of children) {
      const childLength = (child.children && child.children.length) || 0;
      if (childLength > maxChildrenLength) {
        maxChildrenLength = childLength;
      }
      if (child.children) {
        traverse(child.children);
      }
    }
  }
  if (data) traverse(data);
  if (maxChildrenLength <= 1) return 1;
  else if (maxChildrenLength === 2) return 0.8;
  else if (maxChildrenLength > 2) return 0.6;
};

export const findFileCode = (lg_id, selectedType, typ) => {
  const { userPlan } = store.getState().userSummary;
  const usr = userPlan;
  if (usr) {
    let events = usr[`${findEventType(typ).fileTypes}_file_types`];
    let fileCode = events.file_mime_types.find(
      (item) => item.label.toLowerCase() === selectedType.toLowerCase()
    );
    if (fileCode) return fileCode.code;
    return "none";
  }
  return "none";
};

export const updateMetaData = (response) => {
  const self = findSelf();
  const { userPlan, ownedLg } = store.getState().userSummary;
  const updateCachedData = async (item) => {
    let { lgAbout } = await import("../redux/slice/lgabout");
    store.dispatch(
      lgAbout.util.updateQueryData(
        "getLgAbout",
        findWho(item.lg_id)?.link?.split("/")[4],
        (draft) => {
          draft.output = {
            ...draft.output,
            lg_score: item.lg_score,
            engagement_score: item.engagement_score,
          };
        }
      )
    );
  };

  if ("used_flinks" in response) {
    store.dispatch(
      updateUserPlan({ ...userPlan, used_flinks: response.used_flinks })
    );
  }
  if ("updated_scores" in response) {
    response.updated_scores.forEach(async (item) => {
      if (item.lg_id === self.lg_id) {
        if (response.updated_scores.length === 1) {
          updateCachedData(item);
        }
        store.dispatch(
          updateNudgePost({
            nudgeAddRelations: item?.nudgeAddRelations,
            nudgeNewPost: item?.nudgeNewPost,
            nudgeProfileDetails: item?.nudgeProfileDetails,
          })
        );
        let updatedArray = ownedLg.map((ele) => {
          if (ele.lg_id === self.lg_id) {
            return {
              ...ele,
              lg_score: item.lg_score,
              engagement_score: item.engagement_score,
            };
          } else {
            return ele;
          }
        });
        store.dispatch(updateOwnedLg(updatedArray));
      } else {
        updateCachedData(item);
      }
    });
  }
};

export const formatNotificationMessage = (data) => {
  if (data.type === "birth") {
    return t("display.text.text.approachingBirthday", {
      param1: findWho(data?.lg_id)?.name,
      param2: data?.yrs_passed,
      param3: formatDate(data?.anniv_date),
    });
  }
};

export const createNewLifografConfig = (mode) => {
  const { relations, ownedLg } = store.getState().userSummary;
  const self = findSelf();
  const returnOwnedLg = (possibleRelation) => {
    const arr = [];
    possibleRelation.forEach((item) => {
      ownedLg.forEach((item1) => {
        if (item.userId === item1.lg_id) {
          arr.push(item1);
        }
      });
    });
    return arr;
  };

  if (mode === "create") {
    return {
      parents: {
        title: "parents",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "mother" ||
              item.value.toLowerCase() === "father"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      grandParents: {
        title: "grandParents",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "mother" ||
              item.value.toLowerCase() === "father"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      siblings: {
        title: "siblings",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      stepSiblings: {
        title: "stepSiblings",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      uncleAunt: {
        title: "uncleAunt",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      spouse: {
        title: "spouse",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "husband" ||
              item.value.toLowerCase() === "wife"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      children: {
        title: "children",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "son" ||
              item.value.toLowerCase() === "daughter"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      grandChildren: {
        title: "grandChildren",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "son" ||
              item.value.toLowerCase() === "daughter"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("son") ||
                item.relation.toLowerCase().includes("daughter")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      others: { title: "others" },
    };
  } else {
    return {
      parents: {
        title: "parents",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "mother" ||
              item.value.toLowerCase() === "father"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
        desc: "",
      },
      grandParents: {
        title: "grandParents",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "mother" ||
              item.value.toLowerCase() === "father"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      siblings: {
        title: "siblings",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      stepSiblings: {
        title: "stepSiblings",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      uncleAunt: {
        title: "uncleAunt",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("mother") ||
                item.relation.toLowerCase().includes("father")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      spouse: {
        title: "spouse",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "husband" ||
              item.value.toLowerCase() === "wife"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      children: {
        title: "children",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "son" ||
              item.value.toLowerCase() === "daughter"
          );
        },
        relatedToList: () => {
          return returnOwnedLg([{ userId: self.lg_id }]);
        },
      },
      grandChildren: {
        title: "grandChildren",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "son" ||
              item.value.toLowerCase() === "daughter"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("son") ||
                item.relation.toLowerCase().includes("daughter")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      cousin: {
        title: "grandChildren",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("father") ||
                item.relation.toLowerCase().includes("mother")) &&
              item.relation_source === "direct"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      inlaws: {
        title: "grandChildren",
        relationshipList: () => {
          return relationType.filter(
            (item) =>
              item.value.toLowerCase() === "brother" ||
              item.value.toLowerCase() === "sister" ||
              item.value.toLowerCase() === "father" ||
              item.value.toLowerCase() === "mother"
          );
        },
        relatedToList: () => {
          const possibleRelation = relations.filter(
            (item) =>
              (item.relation.toLowerCase().includes("husband") ||
                item.relation.toLowerCase().includes("wife")) &&
              item.relation_source === "marriage"
          );
          return returnOwnedLg(possibleRelation);
        },
      },
      nieceNephew: {
        title: "nieceNephew",
        relationshipList: () => [],
        relatedToList: () => [],
      },
      others: {
        title: "others",
        relationshipList: () => [],
        relatedToList: () => [],
      },
    };
  }
};

export const getTimeDifference = (date1, date2) => {
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();
  const differenceInMillis = Math.abs(time1 - time2);
  const differenceInSeconds = differenceInMillis / 1000;
  const differenceInMinutes = differenceInSeconds / 60;
  const differenceInHours = differenceInMinutes / 60;
  const differenceInDays = differenceInHours / 24;

  return {
    milliseconds: differenceInMillis,
    seconds: differenceInSeconds,
    minutes: differenceInMinutes,
    hours: differenceInHours,
    days: differenceInDays,
  };
};

export const combineHomeFeedAndAniversary = (
  a = [] /* homeFeed Array*/,
  b = [] /*AnivList array */
) => {
  let lastElement = a.pop();
  let result = [...a];
  let c = b.map((item) => {
    return { ...item, comment_type: "aniv" };
  });
  // Step 2: Generate random positions for elements of 'b' within 'a'
  for (let i = 0; i < c.length; i++) {
    // Generate a random index to insert the current element from 'b'
    let randomIndex = Math.floor(Math.random() * (result.length + 1));
    // Insert the element from 'b' into the 'result' array at the random index
    result.splice(randomIndex, 0, c[i]);
  } // Output: An array with elements from 'b' randomly inserted into 'a'
  return [...result, { ...lastElement }];
};

export const isSameDatePreviousYear = (date) => {
  if (date) {
    // Get the month and day of the given date
    const currentMonth = new Date(date).getMonth(); // 0-based index, January is 0
    const currentDay = new Date(date).getDate();

    // Create a new date object for the same date in the previous year
    const previousYearDate = new Date(
      new Date(date).getFullYear() - 1,
      currentMonth,
      currentDay
    );

    // Get the month and day of the date in the previous year
    const previousYearMonth = previousYearDate.getMonth();
    const previousYearDay = previousYearDate.getDate();

    // Compare the month and day
    return currentMonth === previousYearMonth && currentDay === previousYearDay;
  } else {
    return false;
  }
};

export const findOwnerAndSelfUser = () => {
  let arr = [];
  const { ownedLg, relations } = store.getState().userSummary;
  let selfUserLg = [];
  relations.forEach((item) => {
    if (item.self_usr === "Y") {
      selfUserLg.push(item.userId);
    }
  });
  selfUserLg.forEach((item1) => {
    ownedLg.forEach((item2) => {
      if (
        item1 === item2.lg_id &&
        (item2.owner_type === "Owner" || item2.owner_type === "Self")
      ) {
        arr.push(item2);
      }
    });
  });
  return arr;
};

export const formatNotificationParams = (params) => {
  let splitParam = params.split("|");
  let obj = new Object();
  splitParam.forEach((item, idx) => {
    let temp = { [`param${idx + 1}`]: `${item}` };
    Object.assign(obj, temp);
  });
  return obj;
};

export const formatTime = (time) => {
  let hours = new Date(time).getHours();
  let minutes = new Date(time).getMinutes();
  let temp = new Date(time).toLocaleTimeString();
  return `${hours} : ${minutes} ${temp.split(" ")[1]}`;
};
