import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import bgLogo from "../../assets/Img/logoBig.png";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import { useStyles } from "../../utils/Theme";
import { updateHomeFeedNudgeTimer } from "../../redux/slice/authentication";
import { getTimeDifference } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HomeFeedWelcomPromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { nudgePost } = useSelector((state) => state.notification);
  const { mode, currentPage } = useSelector((state) => state.createLg);
  const { homeFeedNudgeTimer } = useSelector((state) => state.authentication);

  const [open, setOpen] = useState(
    mode === null &&
      currentPage === null &&
      (Number(nudgePost.nudgeAddRelations) === 1 ||
        Number(nudgePost.nudgeNewPost === 1) ||
        Number(nudgePost.nudgeProfileDetails === 1)) &&
      (homeFeedNudgeTimer
        ? getTimeDifference(new Date(), homeFeedNudgeTimer).days > 1
        : true)
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClose = () => {
    dispatch(updateHomeFeedNudgeTimer(new Date().toISOString()));
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("display.text.text.welcome")}
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers={true}
          className={classes.root}
          sx={{
            background: `url(${bgLogo})`,
            backgroundSize: "400px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box className="derek">
            <Typography align="justify" sx={{ color: "#000000" }} paragraph>
              {t("display.text.text.homeFeedPromtP1")}
            </Typography>
            <Typography
              align="justify"
              sx={{
                backgroundColor: "#35BC29",
                p: 1,
                borderRadius: 1,
                color: "#FFFFFF",
              }}
              paragraph
            >
              {t("display.text.text.homeFeedPromtP2")}
            </Typography>
            <Typography
              align="justify"
              sx={{
                backgroundColor: "#009FE3",
                p: 1,
                borderRadius: 1,
                color: "#FFFFFF",
              }}
              paragraph
            >
              {t("display.text.text.homeFeedPromtP3")}
            </Typography>
            <Typography
              align="justify"
              sx={{
                backgroundColor: "#F76D57",
                p: 1,
                borderRadius: 1,
                color: "#FFFFFF",
              }}
              paragraph
            >
              {t("display.text.text.homeFeedPromtP4")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button variant="contained" onClick={handleClose}>
              {t("display.text.button.ok")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
